import { render, staticRenderFns } from "./WgPhoneTitle.vue?vue&type=template&id=6dd18cdb&scoped=true"
import script from "./WgPhoneTitle.vue?vue&type=script&lang=js"
export * from "./WgPhoneTitle.vue?vue&type=script&lang=js"
import style0 from "./WgPhoneTitle.vue?vue&type=style&index=0&id=6dd18cdb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dd18cdb",
  null
  
)

export default component.exports