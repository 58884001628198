<template>
  <div class="main">
<!--    <WgPhoneCarousel-->
<!--      :columnIds="$columnIdFile['pclbt']"-->
<!--      @toDetail="toDetail"-->
<!--    />-->
    <WgPhoneCarousel
        :columnIds="'64643eede4b0eee6a5246146'"
        @toDetail="toDetail"
    />
    <WgPhoneNews
      :columnIds="$columnIdFile['ywsd']"
      title="要闻速递"
      @toDetail="toDetail"
      @toList="toList"
    />
    <WgPhoneNotice
      :columnIds="$columnIdFile['tzgg']"
      title="通知公告"
      @toDetail="toDetail"
      @toList="toList"
    />
    <WgPhoneStudy
      :columnIds="$columnIdFile['xsdt']"
      title="学术动态"
      @toDetail="toDetail"
      @toList="toList"
    />
    <WgPhoneList
      :columnIds="[
        $columnIdFile['mtew'],
        $columnIdFile['xykd'],
      ]"
      :title="['媒体二外', '校园看点']"
      @toDetail="toDetail"
      @toList="toList"
    />
    <WgPhoneEnrollment
      title="乘风破浪 二外起航"
      @toDetail="toDetail"
      :columnIds="$columnIdFile['homeRotograph']"
      @toList="toList"
    />
    <WgPhoneSpecial @toDetail="toDetail" @toList="toList"></WgPhoneSpecial>
  </div>
</template>
<script>
import WgPhoneCarousel from "../components/Phone/WgPhoneCarousel.vue";
import WgPhoneNews from "../components/Phone/WgPhoneNews.vue";
import WgPhoneNotice from "../components/Phone/WgPhoneNotice.vue";
import WgPhoneStudy from "../components/Phone/WgPhoneStudy.vue";
import WgPhoneList from "../components/Phone/WgPhoneList.vue";
import WgPhoneEnrollment from "../components/Phone/WgPhoneEnrollment.vue";
import WgPhoneSpecial from "../components/Phone/WgPhoneSpecial.vue";
export default {
  components: {
    WgPhoneEnrollment,
    WgPhoneCarousel,
    WgPhoneNews,
    WgPhoneNotice,
    WgPhoneStudy,
    WgPhoneList,
    WgPhoneSpecial
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toList(item){
      this.$router.push({
        name: "phoneList",
        query: {
          columnId: item.columnId,
          title: item.title,
        },
      });
    },
    toDetail(item){
      if (item.mapProperties) {
        if (item.mapProperties.erOriginLink) {
          window.open(item.mapProperties.erOriginLink);
        } else {
          this.$router.push({
            name: "phoneDetail",
            query: {
              columnId: this.columnId,
              id: item.id,
            },
          });
        }
      } else {
        this.$router.push({
          name: "phoneDetail",
          query: {
            columnId: this.columnId,
            id: item.id,
          },
        });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.main {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
