<template>
  <div class="news_main">
    <WgPhoneTitle
      :columnIds="columnIds"
      :title="title"
      :seeMore="false"
      theme="light"
      msg="BISU ，Because I see you!"
    />
    <div class="title">#招生快讯</div>
    <div class="list">
      <div class="item type1" @click="toDetail(1)">
        <img src="../../assets/img/phone/book3.png" />
        <div class="right">
          <div style="display: flex;align-items: center;">
            <span class="zh">本科生招生</span>
            <span class="en">Undergraduates</span>
          </div>
          <div class="line"></div>
          <div class="msg">
            本科生招生包括英语学院、日语学院、阿拉伯学院、西欧语学院、中欧语学院等...
          </div>
          <div class="more">MORE></div>
        </div>
      </div>
      <div class="item type2"  @click="toDetail(2)">
        <img src="../../assets/img/phone/book1.png" />
        <div class="right">
          <div style="display: flex;align-items: center;">
            <span class="zh">研究生招生</span>
            <span class="en">Graduate Student</span>
          </div>
          <div class="line"></div>
          <div class="msg">
            研究生招生包括外国语言文学、工商管理、中国语言文学、应用经济学和哲学五个...
          </div>
          <div class="more">MORE></div>
        </div>
      </div>
      <div class="item type3"  @click="toDetail(3)">
        <img src="../../assets/img/phone/book2.png" />
        <div class="right">
          <div style="display: flex;align-items: center;">
            <span class="zh">留学生招生</span>
            <span class="en">International Students</span>
          </div>
          <div class="line"></div>
          <div class="msg">
            会计学专业硕士、旅游管理硕士、国际工商管理硕士（MBA）、硕士研究生等...
          </div>
          <div class="more">MORE></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WgPhoneTitle from "./WgPhoneTitle.vue";
export default {
  name: "WgPhoneEnrollment",
  components: { WgPhoneTitle },
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
    toDetail(type) {
      if(type == 1){
        window.open("https://bkzs.bisu.edu.cn/col/col19781/index.html")
      }
      if(type == 2){
        window.open("https://yz.bisu.edu.cn/")
      }
      if(type == 3){
        window.open("http://admission.bisu.edu.cn/")
      }
     // this.$emit("toDetail", item);
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        console.log(val)
        //   if (val) {
        //     const params = {
        //       pageNumber: 0,
        //       pageSize: 10,
        //       columnIds: val,
        //     };
        //     const res = await this.API.basic.getHomeRotographList(params);
        //     this.datas = res.data.content.slice(0, 5);
        //   }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news_main {
  width: 100%;
  height: 1050px;
  background: url("../../assets/img/phone/bg6.jpg");
  background-size: 100% 100%;
  padding: 100px 37px;
  .title {
    //font-family: PingFang;
    font-weight: bold;
    font-size: 36px;
    color: #ffffff;
    margin-top: 65px;
  }
  .list {
    margin-top: 66px;
    .item {
      width: 671px;
      //height: 243px;
      padding: 46px 38px;
      display: flex;
      gap: 39px;
      img {
        width: 78px;
        height: 78px;
      }
      .right {
        .en {
         // font-family: Source Han Serif CN;
          font-weight: 500;
          font-size: 26px;
          color: #333333;
          margin-left: 30px;
        }
        .zh {
          //font-family: PingFang;
          font-weight: bold;
          font-size: 30px;
          color: #000000;
        }
        .line {
          width: 20px;
          height: 2px;
          background: #db0f0f;
          margin-top: 15px;
        }
        .msg {
          //font-family: PingFang;
          font-weight: 400;
          font-size: 20px;
          color: #888888;
          margin-top: 15px;
          display: none;
        }
        .more {

          font-weight: 500;
          font-size: 16px;
          color: #a12916;
          margin-top: 15px;
        }
      }
    }
    .type1 {
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
    }
    .type2 {
      background: #f8f8f8;
    }
    .type3 {
      background: #efefef;
      border-radius: 0px 0px 8px 8px;
    }
  }
}
</style>
