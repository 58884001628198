<template>
  <div class="carousel-main">
    <van-swipe
      autoplay="5000"
      touchable
      indicator-color="#fff"
    >
      <van-swipe-item v-for="item in datas" :key="item.id">
        <video
          ref="videoPlayer"
          v-if="item.storyType == 'VIDEO'"
          type="video/mp4"
          controls
          :poster="item.thumbnailInfo.url"
          :src="item.mapProperties.accessUrl"
          autoplay
          loop
          muted
          style="cursor: pointer; width: 100%"
          @click="toDetail(item)"
        ></video>
        <img
          :src="item.thumbnailInfo.url"
          @click="toDetail(item)"
          style="cursor: pointer;object-fit:fill;"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
export default {
  name: "WgPhoneCarousel",
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        this.activeIndex = 0;
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          let list = [];
          res.data.content.forEach(async (item) => {
            if (item.thumbnailInfo) {
              if (item.thumbnailInfo.url) {
                item.imageUrl = item.thumbnailInfo.url;
                list.push(item);
              }
            }
          });
          this.datas = list;
          console.log('this.datas ', this.datas )
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel-main {
  height: 56.25vw;
  overflow: hidden;
  position: relative;
  ::v-deep .van-swipe__indicators {
    position: absolute;
    left: 10%;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
