<template>
  <div class="wg-phone-special">
    <div>
      <img src="../../assets/img/phone/special1.jpg" @click="go('https://www.bisu.edu.cn/col/col20721/index.html')">
    </div>
    <div>
      <img src="../../assets/img/phone/special2.jpg" @click="$emit('toList', {columnId: '652e011de4b017e9bcb0b404',title: '二十大精神'})">
    </div>
    <div>
      <img src="../../assets/img/phone/special3.jpg" @click="go('https://www.bisu.edu.cn/col/col21301/index.html')" />
    </div>
    <div>
      <img src="../../assets/img/phone/special4.jpg" @click="go('https://cfd.bisu.edu.cn/')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "WgPhoneSpecial",
  methods: {
    go(url) {
      window.open(url);
    }
  }
}
</script>

<style scoped lang="scss">
.wg-phone-special {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px;
  &>div {
    width: calc(50% - 20px);
    margin-top: 20px;
    &:nth-child(even) {
      margin-left: 10px;
    }
  }
}
</style>
