<template>
  <div class="news_main">
    <WgPhoneTitle
        v-if="false"
      :columnIds="columnIds"
      :title="title"
      msg="掌握二外实时资讯，了解最新动态"
      @toList="toList"
    />
    <div class="carousel_main"  v-if="false">
      <van-swipe
        style="height: 100%"
        autoplay="5000"
        touchable
        indicator-color="#C03530"
        @change="onChange"
      >
        <van-swipe-item v-for="(item, index) in datas" :key="index">
          <div class="content">
            <div class="time">
              <span class="day">{{
                $moment(item.publishTime).format("DD")
              }}</span>
              <span class="date">{{
                $moment(item.publishTime).format("YYYY.MM")
              }}</span>
            </div>
            <div class="imgRow">
              <img :src="item.thumbnailInfo.url" style="object-fit: cover" />
              <img class="nextPic" :src="getUrl(index)" style="object-fit: cover" />
            </div>
            <div class="title">
              <div class="ellipsis2">{{ item.title }}</div>
            </div>
            <div class="des">
              <div class="ellipsis3">{{ item.description }}</div>
            </div>
          </div>
        </van-swipe-item>
        <!-- <van-swipe-item>1</van-swipe-item>
        <van-swipe-item>2</van-swipe-item>
        <van-swipe-item>3</van-swipe-item>
        <van-swipe-item>4</van-swipe-item> -->
      </van-swipe>
    </div>
    <div  v-if="false" class="look" @click="toDetail">去看看</div>
    <div class="top-title">
      要闻速递
    </div>
    <div class="img1-container" v-if="datas[0]" @click="toDetail(0)">
      <img :src="datas[0].thumbnailInfo.url" style="object-fit: cover" />
      <div class="title-list" style="position: absolute;bottom: 0;left: 0;background-color: transparent">
        <div class="time-container">
          <div>
            <div class="red-day" style="color: white">{{ $moment(datas[0].publishTime).format("DD") }}</div>
            <div class="red-date" style="color: white">{{ $moment(datas[0].publishTime).format("YYYY.MM") }}</div>
          </div>
        </div>
        <div class="ellipsis3 right-content" style="color: #fff;">
          {{ datas[0].title }}
        </div>
      </div>


    </div>
    <div class="title-list" v-for="(item, index) in datas.slice(1,3)" :key="index" @click="toDetail(index+1)">
      <div class="time-container">
        <div>
          <div class="red-day">{{ $moment(item.publishTime).format("DD") }}</div>
          <div class="red-date">{{ $moment(item.publishTime).format("YYYY.MM") }}</div>
        </div>
      </div>
      <div class="ellipsis3 right-content">
        {{ item.title }}
      </div>
    </div>
    <div class="img1-container" v-if="datas[3]" style="margin-top: 1.04167vw;" @click="toDetail(3)">
      <img class="nextPic" :src="getUrl(0)" style="object-fit: cover" />
      <div class="title-list" style="position: absolute;bottom: 0;left: 0;background-color: transparent">
        <div class="time-container">
          <div>
            <div class="red-day" style="color: white">{{ $moment(datas[3].publishTime).format("DD") }}</div>
            <div class="red-date" style="color: white">{{ $moment(datas[3].publishTime).format("YYYY.MM") }}</div>
          </div>
        </div>
        <div class="ellipsis3 right-content" style="color: #fff;">
          {{ datas[3].title }}
        </div>
      </div>
    </div>

    <div class="title-list" v-for="(item, index) in datas.slice(4,8)" :key="index" @click="toDetail(index+4)">
      <div class="time-container">
        <div>
          <div class="red-day">{{ $moment(item.publishTime).format("DD") }}</div>
          <div class="red-date">{{ $moment(item.publishTime).format("YYYY.MM") }}</div>
        </div>
      </div>
      <div class="ellipsis3 right-content">
        {{ item.title }}
      </div>
    </div>

  </div>
</template>
<script>
import WgPhoneTitle from "./WgPhoneTitle.vue";
export default {
  name: "WgPhoneNews",
  components: { WgPhoneTitle },
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      datas: [],
      index:0,
    };
  },
  methods: {
    onChange(index) {
      this.index = index
    },
    toDetail(index) {
      this.index = index;
      this.$emit("toDetail", this.datas[this.index]);
    },
    toList(item){
      this.$emit('toList',item)
    },
    getUrl(index) {
      console.log(this.datas[index])
      if (this.datas[index + 1]) {
        return this.datas[index + 1].thumbnailInfo.url;
      }else{
        return this.datas[index + 1 - this.datas.length].thumbnailInfo.url;
      }
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        this.activeIndex = 0;
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);

          this.datas = res.data.content.slice(0, 8);
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ellipsis2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news_main {
  width: 100%;
  background: url("../../assets/img/phone/bg1.png");
  background-size: 100% 100%;
  padding: 100px 37px;
  position: relative;
  .look {
    width: 159px;
    height: 64px;
    background: #c03530;
    border-radius: 32px;
   // font-family: PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    line-height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 80px;
  }
  .carousel_main {
    height: 1000px;
    margin-top: 43px;
    .content {
      .time {
        width: 93px;
        height: 93px;
        border-radius: 8px;
        border: 2px solid #f07a76;
        margin-bottom: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          //font-family: Gotham;
          font-weight: 400;
          color: #f07a76;
        }
        .date {
          font-size: 18px;
        }
        .day {
          font-size: 30px;
        }
      }
      .imgRow {
        display: flex;
        img {
          width: 599px;
          height: 424px;
          background: #000000;
          border-radius: 10px;
          margin-bottom: 56px;
        }
        .nextPic {
          object-position: 0 0;
          width: 50px !important;
          margin-left: 30px;
        }
      }

      .title {
        //font-family: PingFang;
        font-weight: 800;
        font-size: 44px;
        color: #202226;
        margin-bottom: 56px;
        width: 599px;
      }
      .des {
       // font-family: PingFang;
        font-weight: 500;
        font-size: 26px;
        color: #999999;
        width: 599px;
      }
    }
  }
  ::v-deep .van-swipe__indicator {
    background-color: #c03530;
  }
  ::v-deep .van-swipe__indicators {
    left: 80%;
  }
  //  ======  new
  .top-title {
    text-align: center;
    padding: 10px;
    font-size: 54px;
  }
  .title-list {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: #fff;
    margin-top: 30px;
    .time-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .red-day {
        font-size: 70px;
        width: 100%;
        color: rgb(201, 107, 92);
      }
      .red-date {
        color: rgb(201, 107, 92);
        font-size: 24px;
        width: 100%;
      }

    }
    .right-content {
      font-size: 36px;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
  }
  .img1-container {
    position: relative;
  }

}


</style>
